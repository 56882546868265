<template>
  <section class="upload-picture-card">
    <el-upload
        ref="uploadPictureCardFileRef"
        :action="uploadInterfaceIp"
        :headers="headersToken"
        :name="name"
        :before-upload="handleBeforeUpload"
        :on-exceed="handleExceed"
        :on-success="handleSuccess"
        :on-error="handleError"
        :file-list="fileList"
        :limit="limit"
        multiple
        :accept="accept"
        list-type="picture-card">
      <i slot="default" class="el-icon-plus"/>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="createPreviewImageUrl(file)" alt=""/>
        <span class="el-upload-list__item-actions flex align-center justify-around">
                     <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                       <i class="el-icon-zoom-in"/>
                     </span>
                     <span class="el-upload-list__item-download" @click="handleDownload(file)">
                       <i class="el-icon-download"/>
                     </span>
                     <span v-if="showRemove" class="el-upload-list__item-delete" @click="handleRemove(file)">
                       <i class="el-icon-delete"/>
                     </span>
               </span>
      </div>
      <div v-if="showTip" slot="tip" class="el-upload__tip">只能上传{{ accept }}文件，限制最多上传数量：{{ limit }}</div>
    </el-upload>
    <el-dialog append-to-body class="file-preview-dialog" top="5vh" width="40%" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt=""/>
    </el-dialog>
  </section>
</template>

<script>
import {baseConfig} from "@/utils/config";
import {tokenCookies} from "@storage/cookies";
import {MessageError, MessageWarning} from "@custom/message";
import * as uploadFileApi from "@/components/Upload/index";
import _ from "lodash";

export default {
  props: {
    //  文件的唯一值集合，当前系统为uuid
    uuidList: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "file",
    },
    limit: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: ".jpg,.jpeg,.png,.gif",
    },
    // 像素大小[width，height]
    pixel: {
      type: Array,
      default: () => [],
    },
    // 是否提示备注
    showTip: {
      type: Boolean,
      default: true,
    },
    // 是否可添加
    showAdd: {
      type: Boolean,
      default: true,
    },
    // 是否可删除
    showRemove: {
      type: Boolean,
      default: true,
    },
    isType: {
      type: String,
      default: '暂无类型',
    },
  },
  name: "upload-picture-card",
  data() {
    return {
      itr:true,
      uploadInterfaceIp: baseConfig.baseURL + "/files/upload",
      headersToken: {
        "X-Access-Token": tokenCookies.get(),
      },
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  created() {
    // 回显图片
    if (this.uuidList.length !== 0) {
      console.log(this.uuidList)
      this.fileList = uploadFileApi.createFormatUploadImages(this.uuidList);
    }

    if (!this.showAdd) {
      this.$nextTick(() => {
        this.$refs["uploadPictureCardFileRef"].$el.removeChild(this.$refs["uploadPictureCardFileRef"].$children[1].$el);
      });
    }
  },
  watch: {
    //2022/03/23 王江毅 监听uuidList数组传过来的数量不为0，重新渲染图片，为防止提前渲染所添加(数据还没传过来就渲染了)
    /*uuidList(newVal, oldVal) {
        console.log(newVal,oldVal);
        //2022/03/23 王江毅 判断新旧数据是否一致
        let ok = newVal.length === oldVal.length && newVal.every((v, i) => v === oldVal[i]);
        if (newVal.length !== 0 && !ok && oldVal.length!==0) this.fileList = uploadFileApi.createFormatUploadImages(newVal);
    }*/
  },
  methods: {
    handleExceed() {
      MessageWarning(`文件总共只能上传${this.limit}张，请先删除已上传的文件！`);
    },
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        const {name,size } = file;
        // const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isLt2M) {
        //   this.$message.error("上传头像图片大小不能超过 2MB!");
        //   reject(false);
        //   return false;
        // }
        // 上传文件格式
        const acceptArray = this.accept.split(",");
        const extensionIndex = name.lastIndexOf(".");       // 后缀序列
        const extension = name.substring(extensionIndex);   // 文件后缀
        this.fileName = name.substring(0, extensionIndex);  // 文件名称
        let isFileType = acceptArray.includes(extension);
        if (!isFileType) {
          MessageWarning(`请上传${this.accept}格式的文件！`);
          reject(false);
        }

        resolve(isFileType);
      });
    },
    handleRemove: _.debounce(function (file) {
      // 删除
      const fileList = this.$refs.uploadPictureCardFileRef.uploadFiles;
      const index = fileList.indexOf(file);
      fileList.splice(index, 1);
      const uploadFileUrl = uploadFileApi.createFormatUploadImages(fileList);
      this.$emit("on-remove", {file, fileList, uploadFileUrl,isType : this.isType});
    }, 300),
    handleSuccess: _.debounce(function (response, file, fileList,) {
      // 上传成功
      const {resultCode = -1} = response;
      if (resultCode === 1000) {
        this.imageUrl = URL.createObjectURL(file.raw);
        const fileName = this.fileName;
        const info = response.returnObject.info;
        this.$emit("on-success", {fileName, file, info, fileList,isType : this.isType});
      } else {
        this.handleError();
      }
    }, 300),
    handleError() {
      MessageError("文件上传失败", 1000, () => {
        this.fileList = [];
      });
    },
    handlePictureCardPreview(file) {
      console.log(file,this.uploadInterfaceIp)
      // alert('预览')
      // 预览
      this.dialogImageUrl = file.url;
      // this.dialogImageUrl = 'https://n.sinaimg.cn/sinakd10123/56/w690h966/20210418/8f90-knvsnuh3426644.jpg';
      this.dialogVisible = true;
    },
    handleDownload(file) {
      // 下载文件
      uploadFileApi.downloadUploadFile(file);
    },
    createIsImageExtension(file) {
      // 判断文件是否是图片格式
      const {extension} = uploadFileApi.createFileNameExtension(file);
      return uploadFileApi.imagesExtension.includes(extension);
    },
    createPreviewImageUrl(file) {
      // 预览图
      return file.url
      // 根据后缀展示不同预览图
      // const { extension } = uploadFileApi.createFileNameExtension(file);
      // if (this.createIsImageExtension(file)) return file.url;
      // return uploadFileApi.filePreviewImage[0].imgSrc;
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.upload-picture-card {
  $imageWidth: 88px;
  $imageHeight: 88px;

  .el-upload--picture-card {
    width: $imageWidth;
    height: $imageHeight;
    line-height: $imageHeight;
    box-sizing: border-box;

  }

  // 预览
  .el-upload-list--picture-card {
    line-height: 0;
    .el-upload-list__item {
      width: $imageWidth;
      height: $imageHeight;
      box-sizing: border-box;
    }

    .el-upload-list__item-thumbnail {
      width: $imageWidth;
      height: $imageHeight;
      padding: 5px;
      box-sizing: border-box;
    }

    .el-upload-list__item-actions span {
      margin: 0;

      &:hover {
        color: #409EFF;
      }

      &.el-upload-list__item-download {
        margin: 0 5px;
      }
    }
  }

  .el-upload__tip {
    line-height: 15px;
  }
}

.file-preview-dialog {
  z-index: 9999 !important;
}

</style>
